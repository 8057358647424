import {
  AdyenPlatformExperience,
  PayoutsOverview,
  PayoutDetails,
  DevEnvironment,
} from "@adyen/adyen-platform-experience-web";
import "@adyen/adyen-platform-experience-web/adyen-platform-experience-web.css";
import { useEffect, useRef, useState } from "react";
import {
  getAdyenComponentSessionBody,
  getPayoutComponentDetail,
} from "../services/transactionsService";
import { useParams } from "react-router";
import { waitForElement } from "../utils/dom";
import Button from "../components/Button";
import { createRoot } from "react-dom/client";
import { CommonProviders } from "..";
import useCurrentUserAccount from "../hooks/data/queries/useCurrentUserAccount";
import Input from "../components/Input";
import { generatePayoutCsv } from "../utils/payout";

const adyenComponentsEnv: DevEnvironment =
  (process.env.REACT_APP_ADYEN_COMPONENTS_ENV as DevEnvironment) ?? "test";

type PayoutParams = { accountHolderCode: string };

async function getAdyenPlatformExperience(accountHolderCode: string) {
  return await AdyenPlatformExperience({
    locale: "en-US",
    environment: adyenComponentsEnv,
    async onSessionCreate() {
      const sessionData = await getAdyenComponentSessionBody({
        allowOrigin: process.env.REACT_APP_BASE_URI?.slice(0, -1) ?? "",
        accountHolderId: accountHolderCode,
        roles: ["Payouts Overview Component: View"],
      });

      return sessionData;
    },
  });
}

function Payout(): JSX.Element {
  const { accountHolderCode: accountHolderCodeParam } =
    useParams<PayoutParams>();
  const { data: currentUserAccountData } = useCurrentUserAccount();
  const [selectedAccountHolderCode, setSelectedAccountHolderCode] =
    useState<string>("");
  const currentAccountHolderCode =
    currentUserAccountData?.type === "CSR"
      ? selectedAccountHolderCode
      : accountHolderCodeParam;

  const payoutsOverviewRef = useRef<HTMLDivElement | null>(null);
  const payoutsDetailsRef = useRef<HTMLDivElement | null>(null);

  const showReport =
    currentUserAccountData?.type === "CSR" ||
    accountHolderCodeParam === currentUserAccountData?.accountHolderCode;

  async function mountPayoutsOverviewComponent(node: HTMLElement) {
    const payoutsOverview = new PayoutsOverview({
      core: await getAdyenPlatformExperience(currentAccountHolderCode ?? ""),
      onRecordSelection(selection) {
        selection.showModal();
        attachDownloadButtonToDetail(
          selection.balanceAccountId,
          selection.date
        );
      },
    });
    payoutsOverview.mount(node);
  }

  async function mountPayoutsDetailsComponent(node: HTMLElement) {
    const payoutsDetail = new PayoutDetails({
      core: await getAdyenPlatformExperience(currentAccountHolderCode ?? ""),
      id: "",
      date: "",
    });
    payoutsDetail.mount(node);
  }

  async function attachDownloadButtonToDetail(
    balanceAccountId: string,
    date: string
  ) {
    await waitForElement(".adyen-pe-payout-data");
    const detailTitle = document.querySelector(
      ".adyen-pe-overview-details--title"
    );

    const downloadButtonContainer = document.createElement("div");
    downloadButtonContainer.id = "download-detail";
    detailTitle?.classList.add("flex", "justify-between", "py-2");
    detailTitle?.appendChild(downloadButtonContainer);

    createRoot(downloadButtonContainer).render(
      <CommonProviders>
        <PayoutDetailDownloadButtonCell
          accountHolderCode={currentAccountHolderCode ?? ""}
          reportDate={date.substring(0, 10)}
          balanceAccountId={balanceAccountId}
        />
      </CommonProviders>
    );
  }

  useEffect(() => {
    mountPayoutsOverviewComponent(payoutsOverviewRef.current as HTMLElement);
    mountPayoutsDetailsComponent(payoutsDetailsRef.current as HTMLElement);
  });

  function handleGenerateReport(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const form = e.currentTarget;
    const formElements = form.elements as typeof form.elements & {
      accountHolderCode: HTMLInputElement;
    };
    if (formElements.accountHolderCode) {
      setSelectedAccountHolderCode(formElements.accountHolderCode.value);
    }
  }

  return (
    <>
      {currentUserAccountData?.type === "CSR" && (
        <form onSubmit={handleGenerateReport}>
          <div className="my-8 flex gap-x-2 px-6">
            <Input
              id="accountHolderCode"
              label="Account Holder"
              className="w-48"
            />
            <Button size="sm" type="submit">
              Generate
            </Button>
          </div>
        </form>
      )}
      {currentAccountHolderCode && showReport && (
        <>
          <div className="payouts-overview" ref={payoutsOverviewRef} />
          <div className="payouts-details hidden" ref={payoutsDetailsRef} />
        </>
      )}
    </>
  );
}

export default Payout;

type PayoutDetailDownloadButtonCellProps = {
  accountHolderCode: string;
  reportDate: string;
  balanceAccountId: string;
};

function PayoutDetailDownloadButtonCell({
  accountHolderCode,
  reportDate,
  balanceAccountId,
}: PayoutDetailDownloadButtonCellProps) {
  const [isLoading, setIsLoading] = useState(false);

  async function handleDownloadClick(e: React.MouseEvent) {
    e.preventDefault();

    setIsLoading(true);
    const data = await getPayoutComponentDetail({
      accountHolderCode,
      balanceAccountId,
      reportDate,
    });
    generatePayoutCsv(data, accountHolderCode, balanceAccountId, reportDate);
    setIsLoading(false);
  }

  return (
    <Button onClick={handleDownloadClick} size="sm" disabled={isLoading}>
      Download
    </Button>
  );
}
